<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px; height: 100%;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 0;") CÓMO UTILIZARLO
          div.d-flex.flex-column.justify-center.fill-height.pl-10
            ul
              li El IPB nos permite ver nuestro nivel de precios de productos comodities respecto a la  competencia.
              li Si nuestro IPB es bajo respecto a la competencia, tenemos opciones de subir algo los precios en aquellos productos que estén por debajo de la competencia.
              li Por el contrario no subiríamos en aquellos productos que ya están al mismo o superior precio.
              li Así mismo el IPB nos permite ir ajustando precios de estos productos a la variaciones del IPC o del Ticket medio de mercado.

  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px; height: 100%;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 0;") HOW TO USE
          div.d-flex.flex-column.justify-center.fill-height.pl-10
            ul
              li The IPB allows us to see our price level of commodity products concerning the competition and track it over time with respect to the competition and against common or industry-specific economics indexes.
              li If our IPB is low concerning the competition, we can raise the prices somewhat in products below the competition. On the opposite, we would not increase in those ones already at the same or higher price.
              li Likewise, the IPB allows us to adjust the prices of these products to the variations of the CPI or the average market Ticket.
</template>

<script>
export default {
  name: "Card_3",
  components: {}
}
</script>

<style scoped>
ul {
  list-style: none;
}

ul li {
  position: relative;
  margin-bottom: 2em;
}

ul li::before {
  content: "\2022";
  color: #4A90E2;
  font-weight: bold;
  display: inline-block;
  width: 10px;
  height: 10px;
  font-size: 33px;
  position: absolute;
  top: -14px;
  left: -24px;
}
</style>
